// components/Footer.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();
  
    return (
      <footer>
        <p>&copy; {currentYear} Taxi Habib. {t('allRightsReserved')}</p>
        <p>
          {t('phone')}: <a href="tel:+41764326400">+41 76 432 64 00</a> | {t('email')}: <a href="mailto:baseltaxihabib@gmail.com">baseltaxihabib@gmail.com</a>
        </p>
      </footer>
    );
  };

export default Footer;
