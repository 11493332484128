// components/Header.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header>
      <h1>Taxi Habib</h1>
      <nav>
        <ul>
          
          <li><a href="#reservation">{t('reservation')}</a></li>
          
          <li><a href="#contact">{t('contact')}</a></li>
        </ul>
        <div className="language-switcher">
          <label>{t('language')}:</label>
          <select onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>
            <option value="de">{t('german')}</option>
            <option value="en">{t('english')}</option>
            <option value="fr">{t('french')}</option>
          </select>
        </div>
      </nav>
    </header>
  );
};

export default Header;
