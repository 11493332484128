// components/ContactForm.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../services/api';
import CustomModal from './CustomModal';

const ContactForm = () => {
  const { t } = useTranslation();
  const [contactData, setContactData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: '',
    message: '',
  });

  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await API.post('/contact', contactData);
      setModalInfo({
        show: true,
        title: 'contactSuccessTitle',
        message: 'contactSuccessMessage',
      });
      // Formular zurücksetzen
      setContactData({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    } catch (error) {
      console.error('Fehler beim Senden der Nachricht:', error);
      setModalInfo({
        show: true,
        title: 'errorTitle',
        message: 'contactErrorMessage',
      });
    }
  };

  return (
    <div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <h2>{t('contactUs')}</h2>
        <div className="form-grid">
          <div className="form-group">
            <label>
              {t('name')}:
              <input
                type="text"
                name="name"
                value={contactData.name}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              {t('email')}:
              <input
                type="email"
                name="email"
                value={contactData.email}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              {t('subject')}:
              <input
                type="text"
                name="subject"
                value={contactData.subject}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="form-group" style={{ flex: '1 1 100%' }}>
            <label>
              {t('message')}:
              <textarea
                name="message"
                value={contactData.message}
                onChange={handleChange}
                required
              ></textarea>
            </label>
          </div>
        </div>
        <button type="submit">{t('submit')}</button>
      </form>

      {/* Modalfenster */}
      <CustomModal
        show={modalInfo.show}
        onHide={() => setModalInfo({ ...modalInfo, show: false })}
        title={t(modalInfo.title)}
        message={t(modalInfo.message)}
      />
    </div>
  );
};

export default ContactForm;
