import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n'; // Importiere die i18n-Konfiguration
import 'bootstrap/dist/css/bootstrap.min.css';

const loadGoogleMapsAPI = () => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  return new Promise((resolve, reject) => {
    if (!apiKey) {
      console.error('Google API Key ist nicht definiert!');
      reject('API Key fehlt');
    }

    if (window.google) {
      // API ist bereits geladen
      resolve(window.google);
      return;
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true; // Optimiert das Laden der API
    script.onload = () => {
      console.log('Google Maps API geladen!');
      resolve(window.google);
    };
    script.onerror = () => {
      console.error('Fehler beim Laden der Google Maps API');
      reject('Fehler beim Laden der Google Maps API');
    };
    document.head.appendChild(script);
  });
};

// Ladeindikator anzeigen, bis die API verfügbar ist
const LoadingScreen = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <h1>Loading...</h1>
  </div>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<LoadingScreen />);

loadGoogleMapsAPI()
  .then(() => {
    console.log('Google Maps API erfolgreich initialisiert');

    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  })
  .catch((error) => {
    console.error('App konnte nicht geladen werden:', error);

    root.render(
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <h1>Fehler: Google Maps API konnte nicht geladen werden.</h1>
      </div>
    );
  });

reportWebVitals();
