// components/Home.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReservationForm from './ReservationForm';
import Reviews from './Reviews';
import ContactForm from './ContactForm';

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="home">
      <section id="welcome">
        <h2>{t('welcome')}</h2>
        <p>{t('intro')}</p>
        <a href="#reservation" className="button">
          {t('bookNow')}
        </a>
      </section>

      <section id="reservation">
        <ReservationForm />
      </section>

      <section id="reviews">
        <Reviews />
      </section>

      <section id="contact">
        <ContactForm />
      </section>
    </div>
  );
};

export default Home;
