// components/ReservationForm.js
import React, { useState, useEffect  } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../services/api';
import CustomModal from './CustomModal';
import PlacesAutocomplete from 'react-places-autocomplete';

const ReservationForm = () => {
  const { t } = useTranslation();
  const [googleAPILoaded, setGoogleAPILoaded] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    pickupAddress: '',
    destinationAddress: '',
    passengers: 1,
    immediate: true,
    reservationDate: '',
    comment: '',
  });

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: '',
    message: '',
  });

  // Prüfen, ob die Google API verfügbar ist
  useEffect(() => {
    if (window.google) {
      setGoogleAPILoaded(true);
    }
  }, []);

  const handleSelectPickup = (address) => {
    setFormData((prevData) => ({
      ...prevData,
      pickupAddress: address,
    }));
  };

  const handleSelectDestination = (address) => {
    setFormData((prevData) => ({
      ...prevData,
      destinationAddress: address,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === 'checkbox'
          ? checked
          : name === 'passengers'
          ? Number(value)
          : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await API.post('/reservations', formData);
      setModalInfo({
        show: true,
        title: 'reservationSuccessTitle',
        message: 'reservationSuccessMessage',
      });
      // Formular zurücksetzen
      setFormData({
        name: '',
        phoneNumber: '',
        pickupAddress: '',
        destinationAddress: '',
        passengers: 1,
        immediate: true,
        reservationDate: '',
        comment: '',
      });
    } catch (error) {
      console.error('Fehler beim Senden der Reservierung:', error);
      setModalInfo({
        show: true,
        title: 'errorTitle',
        message: 'reservationErrorMessage',
      });
    }
  };

  if (!googleAPILoaded) {
    return <h1>{t('loading')}</h1>; // Zeige Ladeanzeige an, wenn API nicht verfügbar
  }

  // Innerhalb von ReservationForm.js
  return (
    <div>
      <form className="reservation-form" onSubmit={handleSubmit}>
        <h2>{t('reservation')}</h2>
        <div className="form-grid">
          <div className="form-group">
            <label>
              {t('name')}:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              {t('phoneNumber')}:
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              {t('pickupAddress')}:
              <PlacesAutocomplete
                value={formData.pickupAddress}
                onChange={(address) =>
                  setFormData((prevData) => ({ ...prevData, pickupAddress: address }))
                }
                onSelect={handleSelectPickup}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: t('pickupAddressPlaceholder'),
                        required: true,
                      })}
                    />
                    <div className="autocomplete-dropdown">
                      
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        return (
                          <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, { className })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </label>
          </div>
          <div className="form-group">
            <label>
              {t('destinationAddress')}:
              <PlacesAutocomplete
                value={formData.destinationAddress}
                onChange={(address) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    destinationAddress: address,
                  }))
                }
                onSelect={handleSelectDestination}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: t('destinationAddressPlaceholder'),
                        required: true,
                      })}
                    />
                    <div className="autocomplete-dropdown">
                      
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        return (
                          <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, { className })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </label>
          </div>
          <div className="form-group">
            <label>
              {t('passengers')}:
              <select
                name="passengers"
                value={formData.passengers}
                onChange={handleChange}
                required
              >
                {/* Optionen */}
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
            </label>
          </div>
          <div className="form-group">
            <label>
              {t('immediatePickup')}:
              <input
                type="checkbox"
                name="immediate"
                checked={formData.immediate}
                onChange={handleChange}
              />
            </label>
          </div>
          {!formData.immediate && (
            <div className="form-group">
              <label>
                {t('dateAndTime')}:
                <input
                  type="datetime-local"
                  name="reservationDate"
                  value={formData.reservationDate}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
          )}
          <div className="form-group" style={{ flex: '1 1 100%' }}>
            <label>
              {t('comment')} ({t('optional')}):
              <textarea
                name="comment"
                value={formData.comment}
                onChange={handleChange}
              ></textarea>
            </label>
          </div>
        </div>
        <button type="submit">{t('submit')}</button>
      </form>

      {/* Modalfenster */}
      <CustomModal
        show={modalInfo.show}
        onHide={() => setModalInfo({ ...modalInfo, show: false })}
        title={t(modalInfo.title)}
        message={t(modalInfo.message)}
      />
    </div>
  );

};

export default ReservationForm;
