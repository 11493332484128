// components/Reviews.js
import React, { useState, useEffect } from 'react';
import API from '../services/api';
import { useTranslation } from 'react-i18next';
import '../styles/reviews.css'; 


const Reviews = () => {
  const { t, i18n } = useTranslation();
  const [reviews, setReviews] = useState([]);

  // Bewertungen abrufen
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await API.get('/reviews', {
          params: { lang: i18n.language },
        });
        setReviews(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Bewertungen:', error);
      }
    };
    fetchReviews();
  }, [i18n.language]);

  return (
    <div className="reviews">
      <h2>{t('customerReviews')}</h2>
      <div className="review-container">
        {reviews.length > 0 ? (
          reviews.map((review) => (
            <div key={review._id} className="review">
              <p className="review-text">"{review.comment}"</p>
              <p className="review-rating">
                {'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}
              </p>
              <p className="review-author">- {review.name}</p>
            </div>
          ))
        ) : (
          <p>{t('noReviews')}</p>
        )}
      </div>
    </div>
  );
};

export default Reviews;
